@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
.home .mainVisual {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Noto Serif JP', serif;
  font-size: 30px;
  font-weight: bold;
  color: #2c2c2c;
  text-align: left;
  padding: 50px 0;
  position: relative;
}

@media only screen and (min-width: 961px) {
  .home .mainVisual {
    font-size: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .home .mainVisual {
    font-size: 26px;
  }
}

.home .mainVisual span {
  margin: 10px 0 0;
  font-size: 14px;
  display: block;
}

@media only screen and (min-width: 961px) {
  .home .mainVisual span {
    font-size: 16px;
  }
}

.home .mainVisual img {
  width: 200px;
  display: block;
  margin: 0 auto 40px;
}

@media only screen and (min-width: 961px) {
  .home .mainVisual img {
    width: 300px;
  }
}

.home .homeContentBox {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.home .homeContentBox .homeContentPart {
  width: 48%;
  margin: 0 4% 20px 0;
}

.home .homeContentBox .homeContentPart:nth-child(2n) {
  margin: 0 0 20px 0;
}

@media only screen and (min-width: 961px) {
  .home .homeContentBox .homeContentPart {
    width: 24%;
    margin: 0 1.3% 0 0;
  }
  .home .homeContentBox .homeContentPart:nth-child(2n) {
    margin: 0 1.3% 0 0;
  }
  .home .homeContentBox .homeContentPart:nth-child(4n) {
    margin: 0;
  }
}

.home .homeContentBox .imageArea {
  text-align: center;
}

@media only screen and (min-width: 961px) {
  .home .homeContentBox .imageArea {
    margin: 0 0 50px;
  }
}
